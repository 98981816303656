<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="500"
    :title="$t('customer.orders.cancelOrderDialogTitle', [orderNumber])"
  >
    <div class="d-flex align-center pa-6" style="column-gap: 10px">
      <cz-icon :src="mdiAlertCircleOutline" color="negative" />
      <div class="text-subtitle-2 font-weight-semibold negative--text">
        {{ $t('customer.orders.cancelOrderNote', [orderNumber]) }}
      </div>
    </div>
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form :disabled="loading" class="px-6">
        <cz-form-field
          :label="$t('customer.orders.cancellationReason')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.cancellationReason')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="reason"
              multi-line
              :error-messages="errors"
              dense
            />
          </validation-provider>
        </cz-form-field>

        <div class="d-flex justify-center py-1">
          <cz-button
            :title="$t('customer.orders.cancelOrderButtonTitle')"
            color="negative"
            :disabled="invalid"
            large
            dark
            :icon-src="mdiCancel"
            :loading="loading"
            rounded
            @click="$emit('cancel', reason)"
          />
        </div>
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzFormField, CzInput, CzButton, CzIcon } from '@/components';
import { mdiCancel, mdiAlertCircleOutline } from '@mdi/js';
export default {
  name: 'CancelOrderDialog',
  components: {
    CzFormField,
    CzDialog,
    CzInput,
    CzButton,
    CzIcon
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    orderNumber: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiCancel,
      mdiAlertCircleOutline,
      reason: ''
    };
  }
};
</script>

<style></style>
